/* Font-Face */
@font-face {
  font-family: 'SenticoSans';
  src: url('./fonts/SenticoSansDTCond-Light.woff2') format('woff2'), url('./fonts/SenticoSansDTCond-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'SenticoSans';
  src: url('./fonts/SenticoSansDTCond-Regular.woff2') format('woff2'), url('./fonts/SenticoSansDTCond-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'SenticoSans';
  src: url('./fonts/SenticoSansDTCond-Bold.woff2') format('woff2'), url('./fonts/SenticoSansDTCond-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 148px;
  color: #afc7e6;
  font-family: 'SenticoSans', sans-serif;
  line-height: 1.1;
  font-size: 18px;
  font-weight: 400;
  direction: ltr;
  text-align: left;
  background-color: #fcfeff;
}
footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 148px;
  padding: 50px 0;
  border-top: 1px solid #e5f0ff;
}
.create-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}
.create-box-inner-vertical {
  width: 4px;
  height: 24px;
  background-color: #ffffff;
  border-radius: 4px;
  position: absolute;
  left: 50%;
}
.create-box-inner-horizontal {
  width: 24px;
  height: 4px;
  background-color: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  margin-top: -2px;
  left: 10px;
}
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
.hamburger:hover {
  opacity: 0.7; }
.hamburger.is-active:hover {
  opacity: 0.7; }
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #ffffff; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #ffffff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block; }
.hamburger-inner::before {
  top: -10px; }
.hamburger-inner::after {
  bottom: -10px; }

.hamburger--slider .hamburger-inner {
  top: 2px; }
.hamburger--slider .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s; }
.hamburger--slider .hamburger-inner::after {
  top: 20px; }

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg); }
.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0; }
.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg); }








.nav-container {
  background-color: #121f2f;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.nav-home {
  background-color: transparent;
}
.navigation {
  padding-top: 5px;
}
.navigation .navigation__menu {
  line-height: 30px;
  padding: 25px 0;
  margin: 0;
  padding-left: 15px !important;
}
.navigation .navigation__menu li {
  margin-right: 30px;
  display: inline-block;
  vertical-align: middle;
  list-style: none;
}
.navigation .navigation__menu li a {
  color: inherit;
  text-decoration: none;
  font-weight: 300;
}
.navigation .navigation__menu li:last-of-type {
  margin-right: 0;
}
.navigation .navigation__responsiveBtn {
  width: auto;
  height: auto;
  margin: 12px 0 0;
  text-align: right;
}

.navigation .dropdown {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  left: auto;
  right: 0;
  text-align: left;
  padding-left: 0px;
  background-color: #121f2f;
  border: 1px solid #243e5e;
  margin-top: 5px;
  z-index: 0;
}
.navigation li:hover > .dropdown,
.navigation li .dropdown:hover {
  opacity: 1;
  visibility: visible;
  z-index: 1000;
}
.navigation li .dropdown li {
  clear: both;
  width: 100%;
  white-space: nowrap;
}
.navigation li .dropdown li a {
  display: inline-block;
  width: 100%;
  padding: 10px 20px;
}
.navigation li .dropdown li a:hover {
  background-color: #203753;
}
.dropdown::before {
  content: '';
  border-style: solid;
  border-width: 0 9.5px 10px 9.5px;
  border-color: transparent transparent #243e5e transparent;
  position: absolute;
  top: -10px;
  left: 214px;
}
.create::before {
  content: '';
  border-style: solid;
  border-width: 0 9.5px 10px 9.5px;
  border-color: transparent transparent #243e5e transparent;
  position: absolute;
  top: -10px;
  left: 85px;
}
.safe-browsing::before {
  content: '';
  border-style: solid;
  border-width: 0 9.5px 10px 9.5px;
  border-color: transparent transparent #243e5e transparent;
  position: absolute;
  top: -10px;
  left: 127px;
}
.dropdown .checkbox {
  display: inline-block;
  padding: 10px 0;
}
.dropdown .checkbox label {
  margin-left: 20px;
}
.dropdown .checkbox label svg {
  margin-top: -5px;
}
.nav-checkbox-label {
  vertical-align: middle;
  padding: 10px;
  padding-left: 0;
  margin-bottom: 0;
}
li.not-link:hover {
  background-color: #203753;
}

.dropdown-mobile::before {
  display: none;
}
.responsiveMenu .dropdown-mobile li{
  padding: 0;
  border-bottom: 0;
}
.responsiveMenu .dropdown-mobile li:first-child{
  padding-top: 5px;
}
.responsiveMenu .dropdown-mobile li:last-child{
  padding-bottom: 5px;
}
.responsiveMenu .dropdown-mobile .checkbox label svg {
  margin: 0;
}
.responsiveMenu {
  position: absolute;
  top: 80px;
  left: 0;
  background: #121f2f;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  visibility: hidden;
  z-index: 99999;
}
.responsiveMenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}
.responsiveMenu ul li {
  letter-spacing: 2px;
  font-size: 17px;
  padding: 20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.responsiveMenu ul li a {
  color: inherit;
  text-decoration: none;
}

footer .footer__copyright a img {
  width: 175px;
}
footer .footer__copyright span {
  margin-left: 50px;
}
footer .footer__social {
  margin-top: 10px;
  text-align: right;
}
footer .footer__social a {
  margin-right: 15px;
  text-decoration: none;
}
footer .footer__social a:last-of-type {
  margin-right: 0;
}
.footer__social a svg {
  transition: all ease-in-out 0.5s;
  -moz-transition: all ease-in-out 0.5s;
  -webkit-transition: all ease-in-out 0.5s;
  -o-transition: all ease-in-out 0.5s;
}
footer .footer__social a svg {
  width: 22px;
  height: 22px;
  fill: #a4bee2;
}
footer .footer__social a svg:hover {
  fill: #3b649e;
}

section {
  padding-top: 80px;
}
section.home-header, .cover {
  background: url("./images/header.jpg") center top no-repeat;
  background-size: cover;
}
.home-intro h1 {
  font-size: 62px;
  color: #ffffff;
  letter-spacing: 5px;
  padding: 80px 0 30px;
  text-transform: uppercase;
  text-align: center;
  position: relative;
}
.home-intro h1 strong {
  display: block;
}
.home-intro h1::after {
  content: '';
  background: #f7de39;
  background: -moz-linear-gradient(left, #f7de39 0%, #4ad9f6 51%, #5729cb 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, #f7de39), color-stop(51%, #4ad9f6), color-stop(100%, #5729cb));
  background: -webkit-linear-gradient(left, #f7de39 0%, #4ad9f6 51%, #5729cb 100%);
  background: -o-linear-gradient(left, #f7de39 0%, #4ad9f6 51%, #5729cb 100%);
  background: -ms-linear-gradient(left, #f7de39 0%, #4ad9f6 51%, #5729cb 100%);
  background: linear-gradient(to right, #f7de39 0%, #4ad9f6 51%, #5729cb 100%);
  width: 120px;
  height: 5px;
  bottom: 0;
  left: -moz-calc((100% - 120px)/2);
  left: -webkit-calc((100% - 120px)/2);
  left: calc((100% - 120px)/2);
  position: absolute;
  border-radius: 10px;
}
.home-icon-container {
  padding: 70px 0 120px;
}
.home-icon-container img {
  max-width: 240px;
}
.home-icon-container > div {
  padding-left: 30px;
  padding-right: 30px;
}

.home-description {
  text-align: center;
  font-size: 1.8em;
  line-height: 1.2em;
  padding: 0 250px 80px;
  font-weight: 300;
}
.transactions {
  position: relative;
}
.transactions .transactions__polygons {
  overflow: hidden;
  left: 0;
  top: -10px;
  bottom: 0;
  width: 100%;
  position: absolute;
}
.transactions .transactions__polygons .polygon--left1 {
  width: 80px;
  height: 80px;
  left: -30px;
  top: 55px;
  opacity: 0.5;
  -webkit-transform: rotate(25deg);
  -moz-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  transform: rotate(25deg);
  position: absolute;
}
.transactions .transactions__polygons .polygon--left2 {
  width: 22px;
  height: 22px;
  left: 156px;
  top: 210px;
  opacity: 0.4;
  -webkit-transform: rotate(25deg);
  -moz-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  transform: rotate(25deg);
  position: absolute;
}
.transactions .transactions__polygons .polygon--left3 {
  width: 19px;
  height: 19px;
  left: 245px;
  top: 75px;
  opacity: 0.8;
  position: absolute;
}
.transactions .transactions__polygons .polygon--left4 {
  width: 80px;
  height: 80px;
  left: 280px;
  top: 280px;
  opacity: 0.5;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
}
.transactions .transactions__polygons .polygon--left5 {
  width: 40px;
  height: 40px;
  left: 370px;
  top: 0;
  position: absolute;
}
.transactions .transactions__polygons .polygon--right1 {
  width: 80px;
  height: 80px;
  right: -15px;
  top: 85px;
  opacity: 0.5;
  -webkit-transform: rotate(-15deg);
  -moz-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  transform: rotate(-15deg);
  position: absolute;
}
.transactions .transactions__polygons .polygon--right2 {
  width: 26px;
  height: 26px;
  right: 50px;
  top: 5px;
  -webkit-transform: rotate(15deg);
  -moz-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  transform: rotate(15deg);
  position: absolute;
}
.transactions .transactions__polygons .polygon--right3 {
  width: 20px;
  height: 20px;
  right: 90px;
  top: 205px;
  opacity: 0.4;
  position: absolute;
}
.transactions .transactions__polygons .polygon--right4 {
  width: 10px;
  height: 10px;
  right: 175px;
  top: 145px;
  opacity: 0.8;
  position: absolute;
}
.transactions .transactions__polygons .polygon--right5 {
  width: 35px;
  height: 35px;
  right: 400px;
  top: 155px;
  -webkit-transform: rotate(-15deg);
  -moz-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  transform: rotate(-15deg);
  position: absolute;
}

.white-bg {
  background-color: #ffffff;
}

.signup-background {
  min-height: calc(100vh - 148px)
}

.signup-message {
  padding-top: 20px;
}

.form-signin {
  max-width: 300px;
  padding-top: 60px;
  margin: auto;
  /*background-color: #ffffff;*/
}

.form-signin input, .form-signin input:focus {
  outline: none;
  border: none;
}

.form-signin input, .form-signin textarea {
  background: rgba(152, 205, 232, 0.1);
  color: #5d80a2;
  line-height: 30px;
  padding: 5px 5px 5px 15px;
  border-bottom: 1px solid transparent;
  box-shadow: 0 1px 1px 0 rgba(47, 111, 173, 0.2) inset;
  border-radius: 0;
  font-size: 20px;
  font-weight: 300;
}

.form-signin input:focus, .form-signin textarea:focus {
  background: rgba(168, 213, 255, 0.08);
  border-bottom: 1px solid #4fa3f4;
  box-shadow: 0 1px 2px 0 rgba(102, 180, 255, 0.7) inset;
  color: #5d80a2;
}

.form-signin input.invalid {
  background: rgba(255, 195, 168, 0.08);
  border-bottom: 1px solid #f4674f;
  box-shadow: 0 1px 2px 0 rgba(255, 124, 102, 0.6) inset;
  color: #f4674f;
}

.signupfree {
  color: #ffffff;
  text-align: center;
  font-size: 50px;
  text-shadow: 1px 1px #848484;
  margin-bottom: 20px;
}

.btn--blueGr {
  overflow: hidden;
  background: #bff3ff;
  background: -moz-linear-gradient(-45deg, #bff3ff 19%, #b6f0fd 20%, #6fd6ed 28%, #3e94d6 69%);
  background: -webkit-gradient(left top, right bottom, color-stop(19%, #bff3ff), color-stop(20%, #b6f0fd), color-stop(28%, #6fd6ed), color-stop(69%, #3e94d6));
  background: -webkit-linear-gradient(-45deg, #bff3ff 19%, #b6f0fd 20%, #6fd6ed 28%, #3e94d6 69%);
  background: -o-linear-gradient(-45deg, #bff3ff 19%, #b6f0fd 20%, #6fd6ed 28%, #3e94d6 69%);
  background: -ms-linear-gradient(-45deg, #bff3ff 19%, #b6f0fd 20%, #6fd6ed 28%, #3e94d6 69%);
  background: linear-gradient(135deg, #bff3ff 19%, #b6f0fd 20%, #6fd6ed 28%, #3e94d6 69%);
  color: #ffffff;
  text-shadow: -4px -3px 1px rgba(0, 122, 216, 0.35);
  width: 100%;
  font-size: 22px;
  line-height: 35px;
  padding: 15px;
  text-transform: uppercase;
  position: relative;
  outline: none;
  border: none;
  border-radius: 5px;
}

.btn--blueGr::after {
  content: '';
  transition: all ease-in-out 1s;
  background: rgba(255, 255, 255, 0);
  background: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(50%, rgba(255, 255, 255, 0.3)), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(-45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(-45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
  width: 200%;
  height: 100%;
  left: 0;
  top: 0;
  transform: translateX(-100%);
  position: absolute;
}
.btn--blueGr:focus::after, .btn--blueGr:hover::after {
  transform: translateX(100%);
}
.btn--gray {
  overflow: hidden;
  background: gray;
  background: -moz-linear-gradient(-45deg, #333333 19%, #333333 20%, #444444 28%, #666666 69%);
  background: -webkit-gradient(left top, right bottom, color-stop(19%, #333333), color-stop(20%, #333333), color-stop(28%, #444444), color-stop(69%, #666666));
  background: -webkit-linear-gradient(-45deg, #333333 19%, #333333 20%, #444444 28%, #666666 69%);
  background: -o-linear-gradient(-45deg, #333333 19%, #333333 20%, #444444 28%, #666666 69%);
  background: -ms-linear-gradient(-45deg, #333333 19%, #333333 20%, #444444 28%, #666666 69%);
  background: linear-gradient(135deg, #333333 19%, #333333 20%, #444444 28%, #666666 69%);
  color: #ffffff;
  text-shadow: -4px -3px 1px rgba(0, 122, 216, 0.35);
  width: 100%;
  font-size: 22px;
  line-height: 35px;
  padding: 15px;
  text-transform: uppercase;
  position: relative;
  outline: none;
  border: 1px solid;
  border-radius: 5px;
}

.terms {
  margin-top: 20px;
  font-size: 16px;
  width: 100%;
  border-color: #fff;
  opacity: 0.9;
  background-color: rgba(152, 205, 232, 0.1);
  padding: 10px;
  border-radius: 5px;
}
.terms a {
  color: #337ab7;
  transition: .3s;
}
.terms a:hover {
  color: #286090;
}

.checkbox {
  font-size: 18px;
  color: #5d80a2;
}
.checkbox label {
  width: 25px;
  height: 25px;
  background: rgba(152, 205, 232, 0.1);
  margin-right: 10px;
  margin-bottom: 0;
  box-shadow: 0 1px 1px 0 rgba(47, 111, 173, 0.2) inset;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.checkbox input:checked + label svg {
  visibility: visible;
  opacity: 1;
  stroke: aliceblue;
}
.checkbox label svg {
  width: 14px;
  height: 14px;
  margin-top: 7px;
  margin-left: 1px;
}
.checkbox input {
  display: none;
}

.forgot-password {
  color: #ffffff;
  width: 300px;
  border-color: #fff;
  opacity: 0.6;
  margin-bottom: 40px;
  margin-top: 20px;
  border-radius: 6px;
  text-transform: none;
  padding: 6px;
  font-size: 20px;
  transition: all ease-in-out .5s;
}
.forgot-password:hover {
  color: #ffffff;
  opacity: 1;
}
.new-to {
  color: #FFFFFF;
  text-align: center;
  font-size: 42px;
  text-shadow: 1px 1px #848484;
  display: block;
}

.faq {
  padding-top: 60px;
  padding-bottom: 60px;
}
.faq h1 {
  padding-bottom: 40px;
}
.faq h2 {
  padding-top: 40px;
}
.faq p {
  font-size: 22px;
}
.faq p a {
  color: #337ab7;
  transition: .3s;
}
.faq p a:hover {
  color: #286090;
}

.featured {
  padding-bottom: 60px;
}
.featured h3 {
  padding-top: 30px;
  text-align: center;
  text-shadow: 1px 1px #0f3c4b;
}
.featured. unselected h3 {
  text-shadow: none !important;
}
.featured .col-md-3 {
  padding-top: 40px;
}
.featured .card-box {
  background-color: #ffffff;
  height: 100%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.featured .card-box-user {
  background-color: transparent;
  padding: 10px;
}
.featured img {
  max-width: 100%;
}
.img-circle {
  border-radius: 50%;
}
.card-box-user-img, .card-box-user-img-svg {
  width:30px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}
.icon { overflow:visible; height:1em; width:1em; }
.card-box-user-name {
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  max-width: 70%;
}
.card-box-user-name a {
  text-decoration:none;
  color:#999999;
}
.card-box-user-img-link {
  text-decoration:none;
  display: inline-block;
}
.card-box-footer {
  position: absolute;
  bottom: 10px;
  left: 25px;
  right: 25px;
}
.card-box-footer img {
  color:#888;
  width:16px;
  cursor:pointer;
}
.card-box-img {
  text-align: center;
  position: relative;
}
.card-box-img img {
  /* max-height: 250px; */
}
.card-box-desc {
  margin-top:10px;
  margin-left: 10px;
  display:block;
  background-color: #fff;
  overflow: hidden;
  word-break: break-word;
  max-width: 90%;
}
.card-box-desc a {
  text-decoration:none;
}
.card-box-desc a span:nth-child(1) {
  display: block;
  color: #181818;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card-box-desc a span:nth-child(2) {
  display: block;
  font-size: 14px;
  color: #888;
  margin-top:10px
}
.card-box-desc a span:nth-child(3) {
  color: #00468c;
  display:block;
  margin-top:10px;
  font-size:14px;
  display:inline-block;
  padding-bottom: 40px;
}
.card-box-desc a span:nth-child(4) {
  display: block;
  font-size: 14px;
  color: #888;
  margin-top:10px;
  position: relative;
  top: -40px;
}
.card-box-footer img:nth-child(2) {
  margin-bottom: 2px;
  vertical-align: text-bottom;
}
.card-box-footer a:last-child {
  text-decoration:none;
  float:right;
  font-size:14px;
  padding-top: 4px;
  color:#00468c;
}

.featured .header {
  height: 285px;
  background-size: cover;
  background-position: center;
  border-radius: 0 0 5px 5px;
}
.featured.my {
  padding-top: 86px;
}
.my .bio {
  padding-top: 40px;
}
.my .bio .user-img {
  width: 150px;
  margin-bottom: 20px;
}

.dropdown.account-mobile {
  padding-top: 20px;
}
.dropdown.account-mobile::before {
  display: none;
}
.dropdown.account-mobile li:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.my-following .user-type {
  display: inline-block;
  padding: 5px 10px;
  color: #ffffff;
  background-color: #19899a;
  font-size: 14px;
  border-radius: 5px 0;
}
.my-following .user-img {
  text-align: center;
}
.my-following .user-img img {
  /*padding: 10px;*/
  margin-top: 5px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 150px;
  height: 150px;
}
.my-following .user-name {
  text-align: center;
  overflow: hidden;
  margin-top: 20px;
}
.my-following .user-name a {
  text-decoration: none;
  color: #333;
  font-size: 24px;
}
.my-following .ico {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.my-following .ico img {
  width: 40px;
}
.separator {
  width: 90%;
  height: 2px;
  background-color: #ebebeb;
  margin: 20px auto;
}
.my-following .desc {
  padding-top: 10px;
  padding-bottom: 25px;
  margin-bottom: 5px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.my-following .footer {
  position: absolute;
  width: calc(100% - 30px);
  bottom: 20px;
}

.featured.my.settings {
  padding-top: 150px;
}
.settings .category {
  box-shadow: 0 0 5px #bfbfbf;
}
.settings .add-hero {
  /*margin: 0 -15px;*/
}
.settings .category-header{
  padding: 20px;
  background-color: #f2f9fd;
  color: gray;
  box-shadow: 0 2px 2px #e2e2e2;
}
.settings #user-hero-img{
  box-shadow: 0 2px 2px #e2e2e2;
}
.settings .add-hero label {
  cursor: pointer;
  font-size: 50px;
  color: darkgray;
  text-shadow: 2px 2px #ffffff;
  text-align: center;
}
.settings .category-title {
  text-align: center;
  padding-bottom: 20px;
  font-size: 22px;
  color: gray;
}
.settings .add-user-img {
  width: 150px;
  height: 150px;
  margin: auto;
  padding: 30px 0;
}
.settings .user-avatar {
  padding: 30px 20px;
  box-shadow: 0 2px 2px #e2e2e2;
  text-align: center;
}
.settings .form-control {
  max-width: 400px;
  margin: auto;
  border-radius: 5px;
}
.settings #user-user-img {
  width: 150px;
  height: 150px;
}
.settings .add-user-img label {
  border-radius: 50%;
  cursor: pointer;
  color: darkgray;
  text-shadow: 2px 2px #ffffff;
}
.featured.my.settings .form-signin {
  max-width: 100%;
  padding-top: 0;
  margin: auto;
  /* background-color: #ffffff; */
}
.settings .inputs {
  padding-top: 60px;
}
.settings .inputs input {
  max-width: 400px;
}
.settings .inputs #SignupButtonHolder {
  max-width: 300px;
  margin: auto;
}
.settings #SignupButton {
  border-radius: 5px;
}
.settings .checkbox {
  display: inline-block;
  padding: 0;
}
.settings .checkbox input:checked + label svg {
  stroke: #578bb9;
}
.settings select {
  max-width: 400px;
  background-color: rgba(152, 205, 232, 0.1);
  border: 0;
  box-shadow: 0 1px 1px 0 rgba(47, 111, 173, 0.2) inset;
}
.settings .custom-select:focus {
  box-shadow: 0 0 0 1px rgba(0,123,255,.25);
}
.settings .select-label {
  display: inline-block;
  padding-bottom: 4px;
  padding-left: 4px;
}
#user-bio {
  height: 100%;
}
#user-bio:focus,  #packageDescription:focus{
  box-shadow: 0 0 0 1px rgba(0,123,255,.25);
}



.upload h3 {
  padding-top: 0;
  padding-bottom: 20px;
}
.box__uploading,
.box__success,
.box__error {
  display: none;
}
.upload .box {
  font-size: 1.25rem;
  background: rgba(152, 205, 232, 0.1);
  position: relative;
  padding: 100px 20px;
  color: #0f3c4b;
}
.upload .box.has-advanced-upload {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
  transition: outline-offset .15s ease-in-out, background-color .15s linear;
}
.upload .box.is-dragover {
  outline-offset: -20px;
  outline-color: #c8dadf;
  background-color: #fff;
}
.upload .box.has-advanced-upload .box__icon {
  width: 100%;
  height: 80px;
  fill: #92b0b3;
  display: block;
  margin-bottom: 40px;
}
.upload .box__file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.upload .box__file + label {
  max-width: 80%;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
}
.upload .box__button {
  font-weight: 700;
  color: #e5edf1;
  background-color: #39bfd3;
  display: block;
  padding: 8px 16px;
  margin: 40px auto 0;
  border: none;
}
.upload .box__button:hover, .upload .box__button:focus {
  background-color: #0f3c4b;
}
.upload #upload-files img {
  width: 230px;
}




.purchases h2 {
  padding-bottom: 20px;
  padding-top: 20px;
}
.purchases .transactionsTable {
  background: #ffffff;
  border-bottom: 1px solid #cee2ff;
  -webkit-box-shadow: -24px -24px 60px 0 rgba(212, 227, 247, 0.45);
  -moz-box-shadow: -24px -24px 60px 0 rgba(212, 227, 247, 0.45);
  box-shadow: -24px -24px 60px 0 rgba(212, 227, 247, 0.45);
  margin-bottom: 60px;
}
.purchases .transactionsTable td {
  padding: 20px;
  border-bottom: 1px solid #cee2ff;
}
.purchases .transactionsTable td.w20 {
  width: 20px;
  border-bottom: 1px solid transparent;
  padding: 0;
}
.purchases .transactionsTable tr:last-child td {
  border-bottom: 0;
}

.packages .card-box-desc a span:nth-child(2) {
  color: #00468c;
  display: block;
  margin-top: 10px;
  font-size: 14px;
  padding-bottom: 10px;
}

.purchase {
  text-align: center;
}
.purchase h2 {
  padding-top: 30px;
  text-shadow: 1px 1px #0f3c4b;
}
.purchase .package-name {
  padding: 30px 20px 20px;
  font-size: 22px;
}
.purchase .package-image img {
  max-width: 300px;
  border-radius: 5px;
}
.purchase .payment-received {
  padding: 30px 20px 20px;
  font-size: 20px;
}
.purchase .pending-confirmation {
  padding: 0 20px 60px;
  font-size: 20px;
}


.purchase .qr-code {
  padding: 30px 20px 20px;
}
.purchase .amount-remaining {
  padding: 0 20px 20px;
  display: inline-block;
  text-align: center;
  vertical-align: top;
}
/*.purchase .amount-remaining:nth-child(7) {*/
/*  padding-top: 30px;*/
/*}*/
.purchase .amount-remaining span {
  padding-bottom: 10px;
  display: inline-block;
}
.purchase .amount-remaining span.btc-address {
  padding: 10px;
  display: inline-block;
  background-color: #f8f8f8;
  color: #333333;
  border: 2px dashed #d1d1d1;
  margin-top: -8px;
  cursor: pointer;
}
.purchase .amount-remaining a {
  text-decoration: underline;
  /*color: #497fb9;*/
}
.purchase .transaction-fees {
  padding: 10px;
}


.packages .purchased-package, .preview .purchased-package {
  max-width: 600px;
  margin: auto;
  font-size: 20px;
  padding-top: 5px;
  text-align: center;
}




.preview .owl-carousel .owl-item {
  height: 540px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview h3 {
  text-align: center;
  text-shadow: 1px 1px #0f3c4b;
  padding-top: 20px;
  padding-bottom: 20px;
}
.preview .owl-carousel .owl-item img {
  max-height: 540px;
}
.preview .owl-thumb-item img {
  width: 100px;
}
.preview .owl-thumb-item {
  border: 2px solid transparent;
  padding: 0;
  margin: 5px;
}
.preview .owl-thumb-item:focus {
  outline: 0;
}
.preview .owl-thumb-item.active {
  border-color: #333;
}
.preview .owl-thumbs {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 40px;
  overflow: auto;
  white-space: nowrap;
}
.preview .owl-prev, .preview .owl-next {
  font-size: 40px !important;
}
.preview .owl-theme .owl-nav [class*="owl-"]:hover {
  background: transparent;
  color: inherit;
  text-decoration: none;
}
.preview .owl-theme .owl-nav [class*="owl-"] {
  margin: 5px 20px !important;
}
.preview .comments {
  padding-top: 15px;
  font-size: 20px;
}
.preview .comment .user-name {
  padding-top: 20px;
  padding-bottom: 10px;
}
.preview .comments span {
  color: #030303;
}

.package-tag a {
  padding: 6px 10px;
  color: #6191ce;
  display: inline-block;
  border: 1px solid #afc7e6;
  border-radius: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  transition-duration: 0.5s;
}

.package-tag a:hover {
  background-color: #88acda;
  color: #ffffff;
  text-decoration: none;
}

.featured.packages .package-image {
  max-width: 300px;
  margin: 20px auto;
}

.featured.packages .package-image img {
  border-radius: 5px;
}

@media (max-width: 767px) {
  .navigation {
    padding-top: 0;
  }
  section {
    padding-top: 90px;
  }
  footer {
    padding: 20px 0;
    text-align: center;
  }
  footer .footer__copyright span {
    font-size: 14px;
    display: block;
    margin: 10px 0 0;
  }
  footer .footer__copyright a img {
    width: 100px;
  }
  footer .footer__social {
    text-align: center;
  }
  .home-intro h1 {
    font-size: 28px;
    padding-top: 30px;
  }
  .home-icon-container {
    padding: 60px 0;
  }
  .home-icon-container > div:nth-child(3), .home-icon-container > div:nth-child(4) {
    padding-top: 40px;
  }
  .home-icon-container > div:nth-child(1),  .home-icon-container > div:nth-child(3) {
    padding-right: 15px;
  }
  .home-icon-container > div:nth-child(2),  .home-icon-container > div:nth-child(4) {
    padding-left: 15px;
  }
  .home-description {
    font-size: 1.5em;
    padding: 0 0 40px;
  }
  .form-signin {
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .featured.my {
    padding-top: 80px;
  }
  .my .bio {
    margin-top: -190px;
  }
  .featured.my.settings .bio {
    margin-top: 0;
  }
  .my .bio .user-img {
    margin-left: 15px;
  }
  .settings .add-user-img label {
  }
  .purchases .transactionsTable td {
    padding: 10px;
  }
  .purchases h2 {
    text-align: center;
  }
  .purchases .transactionsTable td.w20 {
    display: none;
  }
  .purchase .amount-remaining {
    text-align: center;
  }
  /*.purchase .amount-remaining:nth-child(7) {*/
  /*  display: none;*/
  /*}*/
  .preview .owl-carousel .owl-item {
    height: 345px;
  }
  .preview .owl-carousel .owl-item img {
    max-height: 345px;
  }
}








