@media (min-width: 767px) {
    .container {
        width: 75% !important;
        max-width: 1500px !important;
    }

    .posts {
        width: 75% !important;
        max-width: 550px !important;
    }

    .posts .col-md-3 {
        max-width: 100% !important;
    }
}

.svgSprite {
    width: 0;
    height: 0;
    position: fixed;
    top: 0;
    left: 0;
}

footer {
    background-color: white;
}

.full-width {
    width: 100%;
}

.fadeOut {
    opacity:0;
    visibility: hidden;
    transition: visibility 0.5s, opacity 0.5s;
}

.fadeIn {
    opacity:1 !important;
    visibility: visible !important;
    transition: visibility 0.5s, opacity 0.5s;
}

@media (min-width: 767px) {
    .user-header {
        display: block;
    }

    .mobile-user-header {
        display: none;
    }

    .responsiveMenu {
        display: none;
    }
}

.heavy {
    font-weight: 600 !important;
}

.button-loading-border {
    border-color: #3e94d6;
    animation-name: flash_border;
    animation-duration: .7s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-name: flash_border;
    -webkit-animation-duration: .7s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-name: flash_border;
    -moz-animation-duration: .7s;
    -moz-animation-timing-function: linear;
    -moz-animation-iteration-count: infinite;
}

@keyframes flash_border {
    0% {
        border-color: #bff3ff;
    }
    50% {
        border-color: #b6f0fd;
    }
    100% {
        border-color: #3e94d6;
    }
}

.dropdown-mobile-visible {
    height: 140px;
    overflow: hidden;
    transition: height 0.5s;
}

.dropdown-mobile-hidden {
    height: 0px;
    overflow: hidden;
    transition: height 0.5s;
}

.dropdown-mobile-top {
    top: 7px;
}

.mobile-user-header-name {
    max-width: 200px;
}

/* Start Loading Indicator */
.loading-indicator {
    border: 10px solid #afc7e6;
    border-top: 10px solid #3e94d6;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: loading-indicator-spin 2s linear infinite;
}

@keyframes loading-indicator-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
/* End Loading Indicator */

.center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.blur {
    filter: blur(30px);
}

.avatarImage-large {
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
    border-radius: 50%;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.avatarImage-small {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.hide {
    display: none;
}

@media (min-width: 767px) {
    .upload-container-partial-text {
        display: inline;
    }
}

@media (max-width: 767px) {
    .upload-container-partial-text {
        display: block;
    }
}

@media (max-width: 767px) {
    .mobile-user-header {
        display: block;
        background-image: linear-gradient(to bottom, rgba(175,199,230,0), rgba(18,31,47,1));
        position: relative;
        top: -20px;
        border-radius: 0 0 5px 5px;
    }

    .user-header {
        display: none;
    }

    .user-header-col-padding {
        padding: 0;
    }

    .user-header-name {
        display: inline-block;
        position: relative;
        top: 55px;
    }

    .user-bio-padding {
        padding-top: 20px;
    }

    .user-header {
        display: none;
    }

    .mobile-hide {
        display: none;
    }

    .posts-row {
        margin: 0 !important;
    }

    .post-card-input {
        width: 85% !important;
    }
}

@media (min-width: 767px) {
    .my .bio {
        background-image: linear-gradient(to bottom, rgba(175,199,230,0), rgba(18,31,47,1));
        margin-top: -238px;
        border-radius: 0 0 5px 5px;
    }

    .desktop-hide {
        display: none;
    }

    .desktop-user-control-panel {
        float: right;
        top: -45px;
        position: relative;
        padding-right: 15px;
    }

    .desktop-user-description {
        padding-top: 40px;
    }
}

.top-margin-settings-cover {
    margin-top: -230px;
}

.top-margin-settings-avatar {
    margin-top: 40px;
}

.td-center {
    text-align: center;
}

.content-preview-item {
    position: relative;
    display: inline-block;
    width: 230px;
    text-align: center;
    margin: 10px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    text-overflow: ellipsis;
}

.content-preview-item-is-preview {
    outline: 2px solid #f4674f;
}

.content-preview-item-is-cover {
    outline: 4px solid gold;
}

.content-preview-item-delete {
    position: absolute;
    height: 30px;
    width: 30px !important;
    cursor: pointer;
    z-index: 99;
    right: 5px;
    top: 5px;
}

@media (max-width: 767px) {
    .content-preview-post-item {
        width: 200px !important;
    }

    .content-preview-post-item img {
        width: 200px !important;
    }

    .follow-button-mobile a {
        position: relative;
        left: 10px;
        top: 10px;
        float: right;
        padding: 10px 10px;
        color: #FFFFFF;
        background-color: #0f3c4b;
        display: inline-block;
        border: 1px solid #afc7e6;
        border-radius: 20px;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
        margin-right: 10px;
        margin-bottom: 10px;
        transition-duration: 0.5s;
    }

    .follow-button-desktop {
        display: none;
    }

    .btn--blueGr {
        font-size: 18px;
    }

    .btn--gray {
        font-size: 18px;
    }

    .selection-modal-controls button {
        width: 40% !important;
    }
}

@media (min-width: 767px) {
    .follow-button-desktop a {
        padding: 10px 10px;
        color: #FFFFFF;
        background-color: #0f3c4b;
        display: inline-block;
        border: 1px solid #afc7e6;
        border-radius: 20px;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
        margin-right: 10px;
        margin-bottom: 10px;
        transition-duration: 0.5s;
    }

    .follow-button a:hover {
        background-color: #88acda;
        color: #ffffff;
        text-decoration: none;
    }

    .follow-button-mobile {
        display: none;
    }
}

.pointer {
    cursor: pointer;
}

.modalHolder {
    display: block;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999999;
}

.modalHolder .modal {
    position: relative;
    transition: all ease-in-out 0.5s;
    -moz-transition: all ease-in-out 0.5s;
    -webkit-transition: all ease-in-out 0.5s;
    -o-transition: all ease-in-out 0.5s;
    display: block;
    visibility: hidden;
    background: #fcfeff url("./images/modalsBg.png") no-repeat -40px -30px;
    width: 95%;
    max-width: 820px;
    transform: translateY(-300px);
    margin: 200px auto 30px;
    padding: 70px 0 0;
    margin-top: 100px;
    height: auto !important;
}

.active {
     transform: translateY(0) !important;
 }

.modal__close {
    position: absolute;
    background-color: transparent;
    width: 15px;
    height: 15px;
    right: 30px;
    top: 30px;
    border: 0;
}

svg .modal__close {
    stroke: #5d80a2;
}

.modal__title {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 22px;
    color: #5d80a2;
    margin: 0 50px 40px;
    margin: 0 30px 0;
}

.modal__content {
    padding: 30px 50px;
    margin-bottom: 30px;
    padding: 25px 30px;
    margin-bottom: 0;
}

.modal__footer {
    padding: 15px 20px;
    margin-bottom: 10px;
}

.modal__content .modal__content--white {
    background: #ffffff;
    -webkit-box-shadow: 0 -24px 60px 0 rgba(212, 227, 247, 0.2);
    -moz-box-shadow:  0 -24px 60px 0 rgba(212, 227, 247, 0.2);
    box-shadow:  0 -24px 60px 0 rgba(212, 227, 247, 0.2);
}

.inputsHolder {
    display: block;
    content: "";
    clear: both;
    margin: 35px 0;
}

li .exchangeProgress {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 400;
    margin: 0 70px 20px 0;
    padding-left: 50px;
    font-size: 12px;
    color: rgba(93, 128, 162, 0.5);
    margin-right: 30px;
}

li .exchangeProgress:last-of-type {
     margin-right: 0;
 }

.exchangeProgress__step {
    position: absolute;
    text-align: center;
    width: 40px;
    height: 40px;
    font-size: 26px;
    color: rgba(93, 128, 162, 0.5);
    line-height: 40px;
    left: 0;
    top: -5px;
    border-right: 1px solid rgba(93, 128, 162, 0.1);
}

.exchangeProgress__step.completed {
     background: #fff;
     box-shadow: 0 14px 21px 0 rgba(64, 161, 240, 0.18), 0 3px 8px 0 rgba(64, 161, 240, 0.08);
     border: none;
 }

.exchangeProgress__step.completed.inProgress {
     background: #498cce;
     color: #ffffff;
     border: none;
    -webkit-box-shadow: 0 10px 21px 0 rgba(64, 161, 240, 0.35);
    -moz-box-shadow:  0 10px 21px 0 rgba(64, 161, 240, 0.35);
    box-shadow:  0 10px 21px 0 rgba(64, 161, 240, 0.35);
}

.exchangeProgress__status {
    font-size: 16px;
    color: #5d80a2;
}

.popupBG {
    position: fixed;
    background: rgba(21, 34, 51, 0.75);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.modal__close svg {
    height: inherit;
}

.upload-loading-indicator {
    position: absolute;
    height: 100%;
    width: 100%;
}

.upload-container {
    padding: 5px 20px 15px;
    box-shadow: 0 2px 2px #e2e2e2;
    text-align: center;
}

.upload-dashboard {
    display: block;
    position: relative;
    padding-right: 25px;
    padding-top: 20px;
    padding-left: 25px;
}

.add-files {
    display: flex;
    justify-content: center;
}

.clear-uploads {
    display: inline-block;
    width: 33%;
    cursor: pointer;
    color: #0f3c4b;
    font-weight: 600;
    text-align: right;
}

.files-in-packages {
    display: inline-block;
    width: 33%;
    text-align: left;
}

.gray {
    color: gray;
}

.safe-browsing-checkbox {
    margin-left: 6px !important;
}

.purchase-button {
    max-width: 300px;
    margin-top: 20px;
    margin-bottom: 40px;
    border-radius: 5px;
}

ul {
    padding-inline-start: 0 !important; /* needed to center package preview slider children */
}

.carousel .thumbs-wrapper {
    text-align: center;
}

.add-new-comment {
    padding-left: 15px;
    color: #0f3c4b !important;
    font-weight: 600;
    cursor: pointer;
}

.input-modal-controls {
    display: block;
    text-align: right;
    padding: 20px;
}

.selection-modal-controls {
    display: block;
    text-align: center;
    padding-bottom: 25px;
}

.selection-modal-controls button {
    width: 30%;
    margin-right: 10px;
}

button:focus {
    outline: none;
}

.purchase .package-image {
    width: 50%;
    margin: auto;
}

@media (min-width: 767px) {
    .purchase .financials-containers {
        margin-left: 55px;
    }
}

@media (max-width: 767px) {
    .purchase .package-image {
        width: 100%;
    }
}

.purchase .payment-received {
    color: #0f3c4b;
}

.package-preview-license {
    font-size: 14px !important;
    padding-bottom: 20px;
    color: #0f3c4b;
}

.quick-price-container {
    padding-bottom: 20px;
}

.quick-price {
    padding: 5px 10px;
    color: #6191ce;
    display: inline-block;
    border: 1px solid #afc7e6;
    border-radius: 20px;
    margin-right: 10px;
    margin-bottom: 10px;
    transition-duration: 0.5s;
    cursor: pointer;
}

.quick-price:hover {
    background-color: #88acda;
    color: #ffffff;
    text-decoration: none;
}

.package-share-sheet {
    position: absolute;
    background-color: #ffffff;
    height: 49px;
    top: -20px;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
}

.card-box-img-container {
    overflow: hidden;
}

.share-item {
    display: inline-block;
    padding: 5px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

/*.featured-packages.my h3 {*/
/*    color: #181818;*/
/*}*/

.follow-empty-text-container {
    width: 100%;
    text-align: center;
}

.clone-packages-icon {
    float: right;
}

.edit-packages-icon {
    float: right;
    margin-right: 5px;
    width: 19px !important;
}

.delete-packages-loading-position {
    float: left;
}

.delete-packages-loading-content {
    height: 19px;
    width: 19px;
}

.follow-loading-indicator {
    height: 45px;
    width: 45px;
}

.follow-loading-indicator-position {
    position: relative;
    float: right;
    right: 20px;
    bottom: 5px;
}

.follow-loading-indicator-position-mobile {
    position: relative;
    float: right;
    right: 15px;
    bottom: -5px;
}

.no-padding {
    padding: 0 !important;
}

@media (max-width: 767px) {
    .content-row-file-name {
        max-width: 169px;
    }
    .visible-on-mobile {
        display: block !important;
    }
    .hidden-on-mobile {
        display: none !important;
    }
}

@media (min-width: 767px) {
    .visible-on-desktop {
        display: inline-block !important;
    }
    .hidden-on-desktop {
        display: none !important;
    }
}

.content-row-file-name-size-limit {
    text-overflow: ellipsis;
    overflow: hidden;
}

.block-display {
    display: block !important;
}

.featured h4 {
    padding-top: 20px;
    text-align: center;
}

.setting {
    padding-top: 40px;
}

.name-min-height {
    min-height: 19px;
}

.top-padding {
    padding-top: 50px;
}

.margin-center {
    margin: auto;
}

.purchase .minutes-remaining {
    padding: 0 20px 0px;
    display: inline-block;
    text-align: center;
    vertical-align: top;
}

.btn--blueGr a {
    color: white;
    text-decoration: none;
}

.text-container-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
}

.upload-statusbar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    box-shadow: 0 0 5px #bfbfbf;
    padding: 20px;
    color: #0f3c4b;
    background-color: #fcfeff;
    z-index: 100;
}

.posts-row {
    width: 100%;
    padding-bottom: 30px;
}

.posts-box {
    width: 100%;
    padding-bottom: 5px;
}

.post-caption-user {
    font-weight: bold;
    color: #999999;
    padding-right: 5px;
    cursor: pointer;
}

.post-caption {
    color: #999999;
}

.card-box-comments {
    margin-top: 10px;
    padding-bottom: 10px;
    display:block;
    background-color: #fff;
    overflow: hidden;
    word-break: break-word;
    border-bottom: #dddddd 1px solid;
}

.post-caption-box {
    margin-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    display:block;
    background-color: #fff;
    overflow: hidden;
    word-break: break-word;
    border-bottom: #dddddd 1px solid;
}

.card-box-actions {
    margin-top: 10px;
    padding-bottom: 10px;
    display:block;
    background-color: #fff;
    overflow: hidden;
    word-break: break-word;
}

.post-like-button {
    margin-left: 10px;
    display: inline-block;
    width: 30px;
}

.post-share-button {
    margin-left: 20px;
    display: inline-block;
    width: 30px;
}

.post-like-button, .post-share-button img {
    cursor: pointer;
}

.post-share-sheet {
    position: relative;
    background-color: #ffffff;
    height: 49px;
}

.post-likes {
    font-weight: bold;
    color: #999999;
    float: left;
}

.post-date {
    color: #999999;
    float: right;
}

.card-box-info {
    display: block;
    padding: 10px;
    border-bottom: #dddddd 1px solid;
}

.clear {
    clear: both;
}

.post-card-footer {
    display: block;
    padding: 10px;
}

.post-card-input {
    display: inline-block;
    background-color: #d5dde0;
    width: 90%;
}

.post-card-input:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.post-card-reply-button {
    display: inline-block;
    width: 35px;
    margin-left: 13px;
    cursor: pointer;
}

.post-card-purchase-button-container {
    position: absolute;
    top: 50%;
    left: 20%;
    right: 20%;
}

.post-card-purchase-button {
    text-align: center;
    padding: 10px;
    background-color: #103a6d;
    border-radius: 10px;
    cursor: pointer;
    margin: auto;
    width: fit-content;
}

.post-card-lock-img {
    width: 40px;
    padding-right: 15px;
}

.post-card-price {
    display: inline-block;
    height: 40px;
    line-height: 40px;
}

@media (min-width: 767px) {
    .upload-post {
        padding: 25px 40px !important;
    }
}

.post-card-upload-box-icon {
    height: 45px !important;
    margin-bottom: 25px !important;
}

.post-card-upload-price {
    max-width: 100% !important;
}

.post-card-upload-caption {
    font-size: 1.1em;
}

.post-create-button {
    margin-left: -75px;
}

.post-create-button-mobile {
    float: left;
    margin-top: 20px;
    font-weight: bold;
}

.user-page-content-selector {
    text-align: center;
}

.user-page-content-selector h3 {
    display: inline-block;
}

.user-page-content-selector-posts {
    margin-right: 10px;
    cursor: auto;
}

.user-page-content-selector-packages {
    margin-left: 10px;
    cursor: auto;
}

.user-page-content-selector-unselected {
    text-shadow: none !important;
    cursor: pointer !important;
}